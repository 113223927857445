import { ReactElement } from 'react';

interface IProps {
	children: ReactElement | string;
}

const FormTitle = ({ children }: IProps) => {
	return (
		<div className='flex flex-row py-4 px-6 bg-main_bg_gray'>
			<span className='text-base'>{children}</span>
		</div>
	);
};

export default FormTitle;
