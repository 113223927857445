import { Form, Checkbox } from 'antd-mobile';
import { ReactElement } from 'react';
import styled from '@emotion/styled';

const StyleItem = styled(Form.Item)`
	.adm-list-item-content-arrow{
		display: none !important;
	};
`;

interface IProps {
	name: string;
	label?: JSX.Element | string;
	rules?: any[];
	disabled?: boolean;
	className?:string
    children: ReactElement | string;
	checked?:boolean;
	onClick?:()=>void;
}

const FormCheckbox = ({
	name,
	label,
	rules = [],
	disabled = false,
	className,
    children,
	onClick,
	checked
}: IProps) => {
	return (
		<StyleItem name={name} label={label} rules={rules} onClick={onClick}>
			<Checkbox
				className={className}
				disabled={disabled}
				checked={checked}
				style={{ '--font-size': '14px' }}
			>
                {children}
            </Checkbox>
		</StyleItem>
	);
};
export default FormCheckbox;
