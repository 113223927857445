import styled from '@emotion/styled';

interface WatermarkStyle {
    className?:string;
    style?:string;
};

const Watermark = (props:WatermarkStyle) => {

    const StyleDiv = styled('div')`
        font-size:1.2rem;
        color: #E71316;
        opacity: 0.1;
        transform: rotate(-45deg) translate(-50%);
        left:40%;
        top:30%;
        ${props.style}
     `;

	return (
		<StyleDiv className={`absolute whitespace-nowrap ${props.className}`}>
            TESTING ENVIRONMENT
		</StyleDiv>
	);
};

export { Watermark } ;