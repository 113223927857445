import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const StyleLink = styled('a')`
	text-decoration-line: underline;
`;

const ContactUs = () => {
	const { t } = useTranslation();

	const openAppEmail = () => {
		const email = 'oneapjchampion@thermofisher.com';
		const emailAddr = `mailto:${email}`;
		window.location.href = emailAddr;
	};

	return (
		<StyleLink className='text-base underline text-blue-400' onClick={openAppEmail}>
			{t('home.contactUs')}
		</StyleLink>
	);
};

export { ContactUs };
