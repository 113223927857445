import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import { AuthProvider } from './context/AuthContext';
import AppLayout from '@/layouts/AppLayout';
import { useNavigate } from 'react-router-dom';

import { ApplayoutRoutes } from './routes';
import { initLanguage } from './i18n';
import { useSelector } from 'react-redux';

const AppRoutes = () => {
	const language: any = useSelector<any>((data: any) => data?.user?.language);
	const navigate = useNavigate();
	useEffect(() => {
		const handleTouchMove = () => {
			const activeElement = document.activeElement as HTMLInputElement;
			const needBlurTag =
				activeElement?.tagName === 'INPUT' || activeElement?.tagName === 'TEXTAREA';
			if (activeElement && needBlurTag) {
				activeElement?.blur();
			}
		};
		document.addEventListener('touchmove', handleTouchMove);

		return () => {
			document.removeEventListener('touchmove', handleTouchMove);
		};
	}, []);

	useEffect(() => {
		initLanguage(language?.toUpperCase() || 'EN');
	}, [language]);

	useEffect(() => {
		window.addEventListener('popstate', () => {
			navigate(`${process.env.REACT_APP_BASE_API}`, { replace: true });
		});

		return () => {
			window.removeEventListener('popstate', () => {
				// Do something when the back or forward button is clicked
			});
		};
	}, []);

	return (
		<Routes>
			<Route path='/' element={<AppLayout />}>
				{ApplayoutRoutes.map((route, index) => {
					if (route.children) {
						return (
							<Route key={index} path={route.path}>
								<Route key={index + route.path} index={true} element={route.element} />
								{route.children.map((child, indexChild) => {
									if (child.children) {
										return (
											<Route key={indexChild} path={child.path}>
												<Route key={indexChild + child.path} index={true} element={child.element} />
												{child.children.map((grandChild, indexGrandChild) => (
													<Route
														key={indexGrandChild + grandChild.path}
														path={grandChild.path}
														element={grandChild.element}
													/>
												))}
											</Route>
										);
									} else {
										return (
											<Route key={index + indexChild} path={child.path} element={child.element} />
										);
									}
								})}
							</Route>
						);
					} else {
						return <Route key={index} path={route.path} element={route.element} />;
					}
				})}
			</Route>
		</Routes>
	);
};

function App() {
	return (
		<Router basename={process.env.REACT_APP_BASE_ROUTER_URL}>
			<AuthProvider>
				<AppRoutes />
			</AuthProvider>
		</Router>
	);
}

export default App;
