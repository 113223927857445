import { setScrollTop } from '@/core/utils';
import { userService } from '@/services/user.service';
import { updateStateUser } from '@/stores/reducers/user';
import React, { createContext, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';

const AuthContext = createContext<any>({});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [showChildren, setShowChildren] = useState(
		process.env.NODE_ENV !== 'development' ? false : true
	);
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	useLayoutEffect(() => {
		setTimeout(() => {
			setScrollTop();
		}, 200);
	}, [location]);

	const dispatch = useDispatch();

	useEffect(() => {
		process.env.NODE_ENV !== 'development' && checkUser();
	}, []);

	const checkUser = async () => {
		setShowChildren(false);
		const code = searchParams.get('code');
		code && navigate(`${process.env.REACT_APP_BASE_API}`, { replace: true });
		const userInfoArr: any = await userService.getUserInfo(code);
		//console.log('userInfo', userInfo);

		const userInfo: any = {};

		if (userInfoArr?.length > 1) {
			//console.log('userInfo tokens', userInfoArr);
			const firstName = userInfoArr[0]?.given_name || '';
			const formatFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
			const lastName = userInfoArr[0]?.family_name || '';
			const formatLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

			userInfo.country = userInfoArr[1]?.offloc.split(' ')[0];
			userInfo.email =
				userInfoArr[0]?.upn || userInfoArr[0]?.unique_name || userInfoArr[1]?.preferred_username;
			userInfo.employeeId = userInfoArr[1]?.employeeid;
			userInfo.name = userInfoArr[1]?.name;
			userInfo.formatName = `${formatFirstName} ${formatLastName}`;
			userInfo.userInitials = `${firstName.charAt(0).toUpperCase()}${lastName
				.charAt(0)
				.toUpperCase()}`;
			userInfo.full_department = userInfoArr[1]?.department;

			dispatch(updateStateUser({ userInfo }));
			setShowChildren(true);
		}
	};

	return <AuthContext.Provider value={{}}>{showChildren && children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
