import icons from '@/assets/icons';
import { Modal } from 'antd-mobile';
import i18n from 'i18next';
const ErrorModal = { alert };

ErrorModal.alert = function (options) {
	Modal.show({
		bodyClassName: 'text-center border p-0',
		header: <img alt='err' src={icons.errorIcon} />,
		content: (
			/* Hardcoding error texts as a fallback */
			<span>
				{options?.title ||
					i18n.t('formCommon.defaultErrMsg') ||
					'Oops! Something went wrong, please try again.'}
			</span>
		),
		closeOnAction: true,
		actions: [
			{
				key: 'ok',
				/* Hardcoding error texts as a fallback */
				text: i18n.t('formCommon.ok') || 'OK',
				style: { backgroundColor: '#e71216', padding: '0.5rem', color: 'white' },
			},
		],
	});
};

export { ErrorModal };
