import { useTranslation } from 'react-i18next';

interface IProps {
	title: string;
	notes?: string;
}

const FormOptional = ({ title,notes }: IProps) => {
	const { t } = useTranslation();
	return (
		<div>
			{title} <span className='text-gray-400 text-xs'>({notes || t('formCommon.optional')})</span>
		</div>
	);
};

export default FormOptional;
