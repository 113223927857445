import { Form, TextArea } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

interface IProps {
	name: string;
	label: JSX.Element | string;
	rules?: any[];
	placeholder?: string;
	type?: string;
	disabled?: boolean;
}

const FormTextArea = ({ name, label, rules, placeholder }: IProps) => {
	const { t } = useTranslation();

	const customPlaceholder = placeholder ? placeholder : t('formCommon.pleaseInput');

	return (
		<Form.Item name={name} label={label} rules={rules}>
			<TextArea rows={4} style={{ '--font-size': '14px' }} placeholder={customPlaceholder} />
		</Form.Item>
	);
};

export default FormTextArea;
