import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { rootStore, persistor } from './stores';

import './index.css';
import '@/i18n';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 10,
			refetchOnWindowFocus: false,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<Provider store={rootStore}>
			<PersistGate loading={null} persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<App />
					<ReactQueryDevtools position='bottom-right' />
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>
);

reportWebVitals();
