import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import kr from './locales/kr.json';
import en from './locales/en.json';
import jp from './locales/jp.json';

export enum LanguageType {
	English = 'EN',
	Japanese = 'JP',
	Korean = 'KR',
}

export const resources= {
	[LanguageType.English]: {
		global: en,
	},
	[LanguageType.Japanese]: {
		global: jp,
	},
	[LanguageType.Korean]: {
		global: kr,
	},
};

export const defaultNS = 'global';

export const initLanguage = (lang:string)=>{
	i18n.use(initReactI18next).init({
		lng: lang || LanguageType.English,
		resources,
		defaultNS: 'global',
	});
};


export const countryList =['EN','JP','KR'];

