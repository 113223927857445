import icons from '@/assets/icons';
import { Popup as AntdPopup } from 'antd-mobile';
import { forwardRef, ReactElement, useImperativeHandle, useState } from 'react';

interface IProps {
	title: JSX.Element | JSX.Element[] | string;
	children?: ReactElement;
	height?: string;
}

const Popup = forwardRef(({ title, children, height = 'auto' }: IProps, ref: any) => {
	const [visible, setVisible] = useState(false);

	useImperativeHandle(
		ref,
		() => {
			const openVisible = () => {
				setVisible(true);
			};
			const closeVisible = () => {
				setVisible(true);
			};
			return { openVisible, closeVisible };
		},
		[]
	);

	return (
		<AntdPopup
			visible={visible}
			onMaskClick={() => {
				setVisible(false);
			}}
			bodyStyle={{
				borderTopLeftRadius: '8px',
				borderTopRightRadius: '8px',
				height,
				overflow: 'auto',
				fontSize: '12px',
				lineHeight: '16px',
			}}
		>
			<div className='p-5'>
				<div className='flex justify-center mb-6 font-bold text-base relative'>
					<div>{title}</div>
					<img
						alt='close icon'
						className='absolute right-0'
						src={icons.closeIcon}
						onClick={() => setVisible(false)}
					/>
				</div>
				{children}
			</div>
		</AntdPopup>
	);
});

export { Popup };
